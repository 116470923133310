
var pdfMake
var pdfFonts
var htmlToPdfmake = require("html-to-pdfmake");
(async () => {
    pdfMake = await import("pdfmake/build/pdfmake")
    pdfFonts = await import("pdfmake/build/vfs_fonts")
    pdfMake.default.vfs = pdfFonts.default.pdfMake.vfs
})()
import { config } from "../config/config.mjs"
import QRCode from 'qrcode'
import bwipjs from 'bwip-js';
import { fetchGET, toDouble, toCurrency, toDateString, fetchPOST } from "../js/functions"
import { store } from "../store"
import { getInvoiceTemplate } from "../templates/template_controller.js"
const settings = store.state.settings

export async function viewOrders(orders, routes, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOrdersPdf(orders, routes, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printOrders(orders, routes, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOrdersPdf(orders, routes, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function createOrdersPdf(orders) {
    let qrcode
    let cratesBalance = 0
    let body = []
    let content = []
    let pageBreak = "after"
    let length = orders.length
    let last = length - 1
    let imgDataUrl = await toDataURL(`${settings.company.logo}`)
    let date
    let i = 0
    for (i; i < length; i++) {
        let client = orders[i].client
        let widths = []
        if (i === last) pageBreak = ""
        let data = []
        body = []
        if (client.shipping) {
            client.address = client.shippingAddress
            client.city = client.shippingCity
            client.country = client.shippingCountry
            client.postalcode = client.shippingPostalcode
        }
        date = toDateString(orders[i].date)
        let orderColumns = settings.orderColumns
        orderColumns = !client.includePrices ? orderColumns.filter(c => c.type != 'currency') : orderColumns
        let columnsLength = orderColumns.length
        let emptyRow = Array(columnsLength).fill("")
        let columns = []
        let footers = [{ text: "Aantal:", bold: true, colSpan: 2 }, "", orders[i].items.length]
        for (let a = 0; a < columnsLength; a++) {
            widths.push(orderColumns[a].width)
            columns.push(orderColumns[a].text)
        }
        data.push(columns)
        for (let item of orders[i].items) {
            let row = []
            for (let b = 0; b < columnsLength; b++) {
                if (orderColumns[b].type == "number") row.push(item[orderColumns[b].value])
                else if (orderColumns[b].type == "currency") {
                    if (client.includePrices) row.push(toCurrency(item[orderColumns[b].value]))
                    else row.push("")
                }
                else if (orderColumns[b].type == "html") {
                    let html = htmlToPdfmake(item[orderColumns[b].value])
                    row.push(html)
                }
                else row.push(item[orderColumns[b].value])
            }
            data.push(row)
        }
        if (columnsLength > 2) {
            for (let i = 0; i < columnsLength - 3; i++) {
                footers.unshift("")
            }
            let bonbonText = [{ text: "Vergeet aub niet de kratten te retourneren", style: "header", alignment: "center", colSpan: columnsLength }]
            let bonbonText2 = [{ text: "Anders worden er kosten in rekening gebracht ", style: "subHeader", alignment: "center", colSpan: columnsLength }]
            // let bonbonQrcode = await QRCode.toDataURL(`${config.app.host}/newAccount/${client._id}`)
            // let image = Array(columnsLength).fill("")
            // image[0] = { image: bonbonQrcode, width: 60 }
            data.push(bonbonText)
            data.push(bonbonText2)
            // data.push(image)
            data.push(footers)
            data.push(emptyRow)
        }
        try {
            qrcode = await QRCode.toDataURL("https://www.google.com/maps/search/?api=1&query=" + encodeURI(`${orders[i].client.address},${orders[i].client.postalcode},${orders[i].client.city}`))
            cratesBalance = await fetchPOST("fetchClientCratesBalance", { clientNumber: orders[i].clientNumber })
            cratesBalance = cratesBalance < 0 ? 0 : cratesBalance
            orders[i].cratesBalance = cratesBalance
        }
        catch (e) { console.log(e) }
        body = [
            [{ text: "Te betalen:", style: "totals", alignment: "right" }, { text: toCurrency(orders[i].grossTotal), style: "totals" }],
            [{ text: "Excl. BTW: ", style: "info", alignment: "right" }, { text: toCurrency(orders[i].netTotal), style: "info" }],

        ]
        let arr
        for (let vat of orders[i].VAT) {
            if (vat.amount >= 0)
                arr = [{ text: `BTW ${vat.name} ${vat.amount}%: `, style: "info", alignment: "right" }, { text: `${toCurrency(vat.total)}`, style: "info" }]
            else arr = [{ text: `BTW ${vat.name}: `, style: "info", alignment: "right" }, { text: `${toCurrency(vat.total)}`, style: "info" }]
            body.push(arr)
        }
        arr = [{ text: `Totaal BTW: `, style: "info", alignment: "right" }, { text: `${toCurrency(orders[i].totalVAT)}`, style: "info" }]
        body.push(arr)
        if (!client.includePrices) body = ["", ""]

        content.push(
            {
                text: " ",
                pageHeaderText: orders[i].deliveryTime ? "Ordernr: " + orders[i].orderNumber + "\n" + "Levertijd: " + orders[i].deliveryTime : "Ordernr: " + orders[i].orderNumber,
                orderNumber: orders[i].orderNumber,
                style: "subHeader",
                alignment: "right",
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 200,
                        h: 90,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                margin: [0, 0, 0, 10]
            },
            {
                stack: [
                    { text: client.name, style: "subHeader" },
                    { text: client.address },
                    { text: client.postalcode },
                    { text: client.city },
                    { text: client.phone },
                    { image: qrcode, width: 60, absolutePosition: { x: 170, y: 98 } },
                ],
                absolutePosition: { x: 45, y: 85 }
            },
            {
                stack: [
                    {
                        canvas: [
                            {
                                type: "rect",
                                x: 0,
                                y: 0,
                                w: 160,
                                h: 75,
                                r: 3,
                                lineColor: "black"
                            }
                        ],
                        absolutePosition: { x: 400, y: 100 }
                    },
                    {
                        layout: "noBorders",
                        table: {
                            widths: ["*"],
                            body: [[{ text: "Opmerking:", fontSize: 10 }],
                            [{ text: orders[i].notes }]
                            ]
                        },
                        absolutePosition: { x: 400, y: 100 }
                    },
                    {
                        layout: "lightHorizontalLines",
                        table: {
                            headerRows: 1,
                            widths: widths,
                            body: data
                        },
                        margin: [10, 20, 0, 0],
                    },

                    {
                        canvas: [
                            {
                                type: "rect",
                                x: 0,
                                y: 0,
                                w: 510,
                                h: 100,
                                lineColor: "black"
                            }
                        ],
                        margin: [10, 0, 0, 0],
                    },
                    {
                        layout: "noBorders",
                        table: {
                            headerRows: 1,
                            widths: ["*", "*", "200"],
                            body: [
                                [{
                                    stack: [
                                        { text: "Kratten", style: "subHeader" },
                                        { text: `Geleverd: ${orders[i].totalCrates}` },
                                        { text: "Retour: ______" },
                                        { text: `Saldo: ${orders[i].cratesBalance}` },
                                        { text: "Handtekening:", style: "subHeader" },
                                        { text: " ", style: "subHeader" },
                                        { text: " ", style: "subHeader" },
                                    ]
                                }, "", {
                                    layout: "noBorders",
                                    table: {
                                        headerRows: 1,
                                        widths: ["*", "*"],
                                        body: body
                                    },
                                    margin: [0, 0, 20, 0]
                                }]
                            ]
                        },
                        margin: [15, -100, 0, 0],

                    },
                    { text: settings.orderFooter, margin: [10, 5, 20, 0], fontSize: 8 }

                ],
                pageBreak: pageBreak
            }
        )
    }
    var docDefinition = {
        pageSize: "A4",
        pageMargins: [40, 70, 40, 120],
        content: content,
        styles: {
            company: {
                alignment: "right"
            },
            header: {
                fontSize: 20,
                bold: true
            },
            subHeader: {
                fontSize: 12,
                bold: true
            },
            info: {
                fontSize: 8
            },
            totals: {
                fontSize: 15,
                bold: true
            }
        }
    }
    docDefinition.header = function(currentPage) {
        var rightText = "";
        let svg = "";
        for (var l = 0; l < docDefinition.content.length; l++) {
            if (docDefinition.content[l].pageHeaderText && currentPage >= docDefinition.content[l].positions[0].pageNumber) {
                rightText = docDefinition.content[l].pageHeaderText;
                let orderNumber = docDefinition.content[l].orderNumber;
                svg = bwipjs.toSVG({
                    bcid: 'code128',       // Barcode type
                    text: `${orderNumber}`,    // Text to encode
                    height: 12,              // Bar height, in millimeters
                    includetext: true,            // Show human-readable text
                    textxalign: 'center',        // Always good to set this
                    textcolor: 'ffffff',        // Red text
                });
            }
        }
        var headerObj = {
            columns: [
                {
                    text: "Pakbon",
                    style: "header"
                },
                { image: imgDataUrl, width: 100, absolutePosition: { x: 250, y: 10 } },
                [
                    { text: date, style: "company" },
                    {
                        text: rightText,
                        style: "subHeader",
                        alignment: "right"
                    },
                    { svg: svg, height: 25, width: 50, absolutePosition: { x: 450, y: 50 } }
                ]
            ],
            margin: [40, 10, 40, 0]
        }
        return headerObj;
    }
    let promise = new Promise((resolve, reject) => {
        try {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition)
            resolve(pdfDocGenerator)
        } catch (e) {
            console.error(e)
            reject(e)
        }
    })
    return promise
}
export async function createQuotePdf(quote, options) {
    let data = []
    let widths = []
    let quoteColumns = settings.quoteColumns
    let columnsLength = quoteColumns.length
    let columns = []
    let client = quote.client
    if (client.shipping) {
        client.address = client.shippingAddress
        client.city = client.shippingCity
        client.country = client.shippingCountry
        client.postalcode = client.shippingPostalcode
    }
    let date = toDateString(quote.date)
    for (let i = 0; i < columnsLength; i++) {
        widths.push(quoteColumns[i].width)
        columns.push({ text: quoteColumns[i].text, style: "tableHeader" })
    }
    data.push(columns)
    for (let item of quote.items) {
        let row = []
        for (let i = 0; i < columnsLength; i++) {
            if (quoteColumns[i].type == "number") row.push(item[quoteColumns[i].value])
            else if (quoteColumns[i].type == "currency") row.push(toCurrency(item[quoteColumns[i].value]))
            else if (quoteColumns[i].type == "html") {
                let html = htmlToPdfmake(item[quoteColumns[i].value])
                row.push(html)
            }
            else row.push(item[quoteColumns[i].value])
        }
        data.push(row)
    }
    // let length = quote.items.filter(item => item.amount > 0).length
    // if (options.includePrices) {
    //     data.push(["Aantal:", length, "", "", "","",""])
    //     data.push(["Collis:", quote.totalPackages,"","", "", "", ""])
    //     data.push(["Gewicht:", {text: quote.totalWeight + 'kg'},"","", "", "", ""])
    // }
    // else {
    //     data.push(["Aantal:", length, "","",""])
    //     data.push(["Collis:", quote.totalPackages, "","",""])
    //     data.push(["Gewicht:", {text: quote.totalWeight + 'kg'}, "","",""])
    // }
    let body = [
        [{ text: "Totaal:", style: "totals", alignment: "right" }, { text: toCurrency(quote.grossTotal), style: "totals" }],
        [{ text: "Excl. BTW: ", style: "info", alignment: "right" }, { text: toCurrency(quote.netTotal), style: "info" }],

    ]
    let arr
    for (let vat of quote.VAT) {
        if (vat.amount >= 0)
            arr = [{ text: `BTW ${vat.name} ${vat.amount}%: `, style: "info", alignment: "right" }, { text: `${toCurrency(vat.total)}`, style: "info" }]
        else arr = [{ text: `BTW ${vat.name}: `, style: "info", alignment: "right" }, { text: `${toCurrency(vat.total)}`, style: "info" }]
        body.push(arr)
    }
    arr = [{ text: `Totaal BTW: `, style: "info", alignment: "right" }, { text: `${toCurrency(quote.totalVAT)}`, style: "info" }]
    body.push(arr)
    if (!options.includePrices) body = ["", ""]
    let imgDataUrl = await toDataURL(`${settings.company.logo}`)
    var docDefinition = {
        pageSize: "A4",
        pageMargins: [40, 20, 40, 150],
        footer: (currentPage, pageCount) => {
            if (currentPage == pageCount) {
                return [
                    {
                        canvas: [
                            {
                                type: "rect",
                                x: 0,
                                y: 0,
                                w: 570,
                                h: 130,
                                lineColor: "black"
                            }
                        ],
                        margin: [10, 0, 0, 0],
                    },
                    {
                        layout: "noBorders",
                        table: {
                            headerRows: 1,
                            widths: ["*", "*"],
                            body: [
                                [{
                                    layout: "noBorders",
                                    table: {
                                        widths: ["*"],
                                        body: [[{ text: "Opmerking:", fontSize: 10 }],
                                        [{ text: quote.notes, fontSize: 8 }]
                                        ]
                                    },
                                }, {
                                    layout: "noBorders",
                                    table: {
                                        headerRows: 1,
                                        widths: ["*", "*"],
                                        body: body
                                    },
                                    margin: [0, 0, 20, 0]
                                }]
                            ]
                        },
                        margin: [15, -130, 0, 0],

                    },
                    { text: `pagina ${currentPage} van ${pageCount}`, alignment: "right", margin: [0, 5, 20, 0], fontSize: 8 }
                ]
            }
            else {
                return {
                    text: `pagina ${currentPage} van ${pageCount}`,
                    alignment: "right", margin: [0, 0, 40, 0]
                }
            }
        },
        content: [
            {
                columns: [
                    {
                        text: "Offerte",
                        style: "header"
                    },
                    [
                        { image: imgDataUrl, width: 140, absolutePosition: { x: 250, y: 10 } },
                        { text: date, style: "company" },
                        {
                            text:
                                "Offertenr: " + quote.quoteNumber,
                            style: "subHeader",
                            alignment: "right"
                        }
                    ]
                ]
            },
            {
                layout: "noBorders",
                table: {
                    widths: [75, 50],
                    body: [
                        [
                            {
                                text: "Leverdatum:",
                            },
                            {
                                text: toDateString(quote.deliveryDate),
                            }
                        ],
                        [
                            {
                                text: "Levertijd:",
                            },
                            {
                                text: quote.deliveryTime,
                            }
                        ]
                    ]
                },
                absolutePosition: { x: 420, y: 70 }
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 200,
                        h: 90,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                margin: [0, 20, 0, 10]
            },
            {
                stack: [
                    { text: client.name, style: "subHeader" },
                    { text: client.address },
                    { text: client.postalcode },
                    { text: client.city },
                    { text: client.phone },
                ],
                absolutePosition: { x: 45, y: 77 }
            },
            {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: widths,
                    body: data
                },
                margin: [0, 20, 0, 0]
            },
        ],
        styles: {
            company: {
                alignment: "right",
                fontSize: 12
            },
            header: {
                fontSize: 30,
                bold: true
            },
            subHeader: {
                fontSize: 12,
                bold: true
            },
            info: {
                fontSize: 8
            },
            totals: {
                fontSize: 15,
                bold: true
            },
            tableHeader: {
                bold: true
            }
        }
    }
    let promise = new Promise((resolve, reject) => {
        try {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition)
            resolve(pdfDocGenerator)
        }
        catch (e) {
            console.log(e)
            reject()
        }
    })
    return promise
}
export async function createOrderPdf(order, options) {
    let data = []
    let widths = []
    let qrcode
    let cratesBalance = 0
    let client = order.client
    let orderType = options.orderType > 0 ? options.orderTypes[options.orderType] : "Pakbon"
    if (client.shipping) {
        client.address = client.shippingAddress
        client.city = client.shippingCity
        client.country = client.shippingCountry
        client.postalcode = client.shippingPostalcode
    }
    let date = toDateString(order.date)
    let orderColumns = settings.orderColumns
    orderColumns = !options.includePrices ? orderColumns.filter(c => c.type != 'currency') : orderColumns
    let columnsLength = orderColumns.length
    let emptyRow = Array(columnsLength).fill("")
    let columns = []
    let footers = [{ text: "Aantal:", bold: true, colSpan: 2 }, "", order.items.length]
    for (let i = 0; i < columnsLength; i++) {
        widths.push(orderColumns[i].width)
        columns.push({ text: orderColumns[i].text, style: "tableHeader" })
    }
    data.push(columns)
    for (let item of order.items) {
        let row = []
        for (let i = 0; i < columnsLength; i++) {
            if (orderColumns[i].type == "number") row.push(item[orderColumns[i].value])
            else if (orderColumns[i].type == "currency") {
                if (options.includePrices) row.push(toCurrency(item[orderColumns[i].value]))
                else row.push("")
            }
            else if (orderColumns[i].type == "html") {
                let html = htmlToPdfmake(item[orderColumns[i].value])
                row.push(html)
            }
            else row.push(item[orderColumns[i].value])
        }
        data.push(row)
    }
    if (columnsLength > 2) {
        for (let i = 0; i < columnsLength - 3; i++) {
            footers.unshift("")
        }
        data.push(footers)
        data.push(emptyRow)
    }
    // if (options.includePrices) {
    //     widths = ["*","auto", "auto", "auto", "auto", "auto","auto","auto"]
    //     data.push(["Artikel","Omschrijving", "Aantal","Inhoud","Gewicht", "Prijs", "Subtotaal", "Lotnr",])
    // }
    // else {
    //     widths = ["*","auto", "auto", "auto","auto","auto"]
    //     data.push(["Artikel", "Omschrijving", "Aantal", "Inhoud","Gewicht","Lotnr"])
    // }
    // for (let item of order.items) {
    //     let row = []
    //     if (item.status == -1) {
    //         row.push({ stack: [{ text: item.name, decoration: "lineThrough" }, { text: "Niet op voorraad" }] })
    //         row.push({ text: item.amount + " " + item.unit, decoration: "lineThrough" })
    //         row.push({ text: item.unitAmount , decoration: "lineThrough" })
    //         row.push({ text: item.totalWeight , decoration: "lineThrough" })
    //         if (options.includePrices) {
    //             row.push({ text: toCurrency(item.price), decoration: "lineThrough" })
    //             row.push({ text: toCurrency(item.netSubtotal), decoration: "lineThrough" })
    //         }
    //         row.push({text:item.lotNumber, decoration: "lineThrough"})
    //     }
    //     else {
    //         row.push(item.name)
    //         row.push(htmlToPdfmake(item.description))
    //         row.push({ text: item.amount + " " + item.unit })
    //         row.push(item.unitAmount+'kg')
    //         row.push(item.totalWeight+'kg')
    //         if (options.includePrices) {
    //             row.push(toCurrency(item.price))
    //             if (order.client.includeVAT) row.push(toCurrency(item.grossSubtotal))
    //             else row.push(toCurrency(item.netSubtotal))
    //         }
    //         row.push(item.lotNumber)
    //     }
    //     data.push(row)
    // }
    // let length = order.items.filter(item => item.amount > 0).length
    // if (options.includePrices) {
    //     data.push(["Aantal:", length, "", "", "","","",""])
    //     data.push(["Collis:", order.totalPackages,"","", "", "", "",""])
    //     data.push(["Gewicht:", {text: order.totalWeight + 'kg'},"","", "", "", "",""])
    // }
    // else {
    //     data.push(["Aantal:", length, "","","",""])
    //     data.push(["Collis:", order.totalPackages, "","","",""])
    //     data.push(["Gewicht:", {text: order.totalWeight + 'kg'}, "","","",""])
    // }
    try {
        qrcode = await QRCode.toDataURL("https://www.google.com/maps/search/?api=1&query=" + encodeURI(`${order.client.address},${order.client.postalcode},${order.client.city}`))
        cratesBalance = await fetchPOST("fetchClientCratesBalance", { clientNumber: order.clientNumber })
        cratesBalance = cratesBalance < 0 ? 0 : cratesBalance
    }
    catch (e) {
        console.log(e)
    }
    let body = [
        [{ text: "Te betalen:", style: "totals", alignment: "right" }, { text: toCurrency(order.grossTotal), style: "totals" }],
        [{ text: "Excl. BTW: ", style: "info", alignment: "right" }, { text: toCurrency(order.netTotal), style: "info" }],

    ]
    let arr
    for (let vat of order.VAT) {
        if (vat.amount >= 0)
            arr = [{ text: `BTW ${vat.name} ${vat.amount}%: `, style: "info", alignment: "right" }, { text: `${toCurrency(vat.total)}`, style: "info" }]
        else arr = [{ text: `BTW ${vat.name}: `, style: "info", alignment: "right" }, { text: `${toCurrency(vat.total)}`, style: "info" }]
        body.push(arr)
    }
    arr = [{ text: `Totaal BTW: `, style: "info", alignment: "right" }, { text: `${toCurrency(order.totalVAT)}`, style: "info" }]
    body.push(arr)
    if (!options.includePrices) body = ["", ""]
    let imgDataUrl = await toDataURL(`${settings.company.logo}`)
    var docDefinition = {
        pageSize: "A4",
        pageMargins: [40, 70, 40, 120],
        header: {
            columns: [
                {
                    text: orderType,
                    style: "header"
                },
                { image: imgDataUrl, width: 100, absolutePosition: { x: 250, y: 10 } },
                [
                    { text: date, style: "company" },
                    {
                        text: order.deliveryTime ? "Ordernr: " + order.orderNumber + "\n" + "Levertijd: " + order.deliveryTime : "Ordernr: " + order.orderNumber,
                        style: "subHeader",
                        alignment: "right"
                    },
                ]
            ],
            margin: [40, 10, 40, 0]
        },
        footer: (currentPage, pageCount) => {
            if (currentPage == pageCount) {
                return [
                    {
                        canvas: [
                            {
                                type: "rect",
                                x: 0,
                                y: 0,
                                w: 570,
                                h: 100,
                                lineColor: "black"
                            }
                        ],
                        margin: [10, 0, 0, 0],
                    },
                    {
                        layout: "noBorders",
                        table: {
                            headerRows: 1,
                            widths: ["*", "*", "*"],
                            body: [
                                [{
                                    stack: [
                                        { text: "Kratten", style: "subHeader" },
                                        { text: `Geleverd: ${order.totalCrates}` },
                                        { text: "Retour: ______" },
                                        { text: `Saldo: ${cratesBalance}` },
                                        { text: "Handtekening:", style: "subHeader" },
                                        { text: " ", style: "subHeader" },
                                        { text: " ", style: "subHeader" },
                                        { text: settings.orderFooter }]
                                }, "", {
                                    layout: "noBorders",
                                    table: {
                                        headerRows: 1,
                                        widths: ["*", "*"],
                                        body: body
                                    },
                                    margin: [0, 0, 20, 0]
                                }]
                            ]
                        },
                        margin: [15, -100, 0, 0],

                    },
                    { text: `pagina ${currentPage} van ${pageCount}`, alignment: "right", margin: [0, 5, 20, 0], fontSize: 8 }
                ];
            }
            else {
                return {
                    text: `pagina ${currentPage} van ${pageCount}`,
                    alignment: "right", margin: [0, 0, 40, 0]
                }
            }
        },
        content: [
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 200,
                        h: 90,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                margin: [0, 10, 0, 10]
            },
            {
                stack: [
                    { text: client.name, style: "subHeader" },
                    { text: client.address },
                    { text: client.postalcode },
                    { text: client.city },
                    { text: client.phone },
                    { image: qrcode, width: 60, absolutePosition: { x: 170, y: 98 } },
                ],
                absolutePosition: { x: 45, y: 85 }
            },
            {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: widths,
                    body: data
                },
                margin: [0, 20, 0, 0]
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 160,
                        h: 75,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                absolutePosition: { x: 400, y: 100 }
            },
            {
                layout: "noBorders",
                table: {
                    widths: ["*"],
                    body: [[{ text: "Opmerking:", fontSize: 10 }],
                    [{ text: order.notes }]
                    ]
                },
                absolutePosition: { x: 400, y: 100 }
            },
        ],
        styles: {
            company: {
                alignment: "right",
                fontSize: 12
            },
            header: {
                fontSize: 20,
                bold: true
            },
            subHeader: {
                fontSize: 12,
                bold: true
            },
            info: {
                fontSize: 8
            },
            totals: {
                fontSize: 15,
                bold: true
            },
            tableHeader: {
                bold: true,
            }
        }
    }
    let promise = new Promise((resolve, reject) => {
        try {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition)
            resolve(pdfDocGenerator)
        }
        catch (e) {
            console.log(e)
            reject()
        }
    })
    return promise
}
export async function viewPayments(client, payments, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createPaymentsPdf(client, payments, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printPayments(client, payments, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createPaymentsPdf(client, payments, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function createOpenOrdersPdf(client, orders, options) {
    const { pageSize } = options ? options : { pageSize: "A4" }
    let data = []
    let widths = []
    let total = orders.reduce((acc, cur) => acc + parseFloat(cur.grossTotal), 0)
    total = Math.round(total * 100) / 100
    let date = toDateString(new Date())
    widths = ["*", "*", "*", "*", "*"]
    data.push(["Order", "Datum", "Totaal", "Betaald", "Openstaand"])

    for (let order of orders) {
        let row = []

        row.push(order.orderNumber)
        row.push({ text: toDateString(order.date) })
        row.push(toCurrency(order.grossTotal))
        row.push(toCurrency(order.credit))
        row.push(toCurrency(order.debit))


        data.push(row)
    }

    let imgDataUrl = await toDataURL(`${settings.company.logo}`)
    var docDefinition = {
        pageSize: pageSize,
        pageMargins: [40, 20, 40, 120],
        footer: (currentPage, pageCount) => {
            if (currentPage == pageCount) {
                return [
                    { text: `pagina ${currentPage} van ${pageCount}`, alignment: "right", margin: [0, 5, 20, 0], fontSize: 8 }
                ]
            }
            else {
                return {
                    text: `pagina ${currentPage} van ${pageCount}`,
                    alignment: "right", margin: [0, 0, 40, 0]
                }
            }
        },
        content: [
            {
                columns: [
                    {
                        text: "Te factureren orders",
                        style: "header"
                    },
                    [
                        { text: date, style: "company" },
                        { image: imgDataUrl, width: 140, absolutePosition: { x: 250, y: 10 } },

                    ]
                ]
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 200,
                        h: 90,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                margin: [0, 20, 0, 10]
            },
            {
                stack: [
                    { text: client.name, style: "subHeader" },
                    { text: client.address },
                    { text: client.postalcode },
                    { text: client.city },
                    { text: client.phone },
                ],
                absolutePosition: { x: 45, y: 77 }
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 160,
                        h: 75,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                absolutePosition: { x: 400, y: 100 }
            },
            {
                layout: "noBorders",
                table: {
                    widths: ["*"],
                    body: [[{ text: "Te factureren:", style: "header" }],
                    [{ text: toCurrency(total), style: "header" }]
                    ]
                },
                absolutePosition: { x: 410, y: 100 }
            },
            {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: widths,
                    body: data
                },
                margin: [0, 20, 0, 0]
            },
        ],
        styles: {
            company: {
                alignment: "right"
            },
            header: {
                fontSize: 20,
                bold: true
            },
            subHeader: {
                fontSize: 12,
                bold: true
            },
            info: {
                fontSize: 8
            },
            totals: {
                fontSize: 15,
                bold: true
            }
        }
    }
    let promise = new Promise((resolve, reject) => {
        try {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition)
            resolve(pdfDocGenerator)
        }
        catch (e) {
            console.log(e)
            reject()
        }
    })
    return promise
}
export async function createOutstandingInvoicesPdf(client, invoices, options) {
    const { pageSize } = options ? options : { pageSize: "A4" }
    let data = []
    let widths = []
    let total = invoices.reduce((acc, cur) => acc + parseFloat(cur.debit), 0)
    total = Math.round(total * 100) / 100
    let date = toDateString(new Date())
    widths = ["*", "*", "*", "*", "*", "*"]
    data.push(["Factuur", "Datum", "Vervaldatum", "Totaal", "Betaald", "Openstaand"])

    for (let invoice of invoices) {
        let row = []

        row.push(invoice.invoiceNumber)
        row.push({ text: toDateString(invoice.date) })
        row.push({ text: toDateString(invoice.dueDate) })
        row.push(toCurrency(invoice.grossTotal))
        row.push(toCurrency(invoice.credit))
        row.push(toCurrency(invoice.debit))


        data.push(row)
    }

    let imgDataUrl = await toDataURL(`${settings.company.logo}`)
    var docDefinition = {
        pageSize: pageSize,
        pageMargins: [40, 20, 40, 120],
        footer: (currentPage, pageCount) => {
            if (currentPage == pageCount) {
                return [
                    { text: `pagina ${currentPage} van ${pageCount}`, alignment: "right", margin: [0, 5, 20, 0], fontSize: 8 }
                ]
            }
            else {
                return {
                    text: `pagina ${currentPage} van ${pageCount}`,
                    alignment: "right", margin: [0, 0, 40, 0]
                }
            }
        },
        content: [
            {
                columns: [
                    {
                        text: "Openstaande facturen",
                        style: "header"
                    },
                    [
                        { text: date, style: "company" },
                        { image: imgDataUrl, width: 140, absolutePosition: { x: 250, y: 10 } },

                    ]
                ]
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 200,
                        h: 90,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                margin: [0, 20, 0, 10]
            },
            {
                stack: [
                    { text: client.name, style: "subHeader" },
                    { text: client.address },
                    { text: client.postalcode },
                    { text: client.city },
                    { text: client.phone },
                ],
                absolutePosition: { x: 45, y: 77 }
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 160,
                        h: 75,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                absolutePosition: { x: 400, y: 100 }
            },
            {
                layout: "noBorders",
                table: {
                    widths: ["*"],
                    body: [[{ text: "Openstaand:", style: "header" }],
                    [{ text: toCurrency(total), style: "header" }]
                    ]
                },
                absolutePosition: { x: 410, y: 100 }
            },
            {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: widths,
                    body: data
                },
                margin: [0, 20, 0, 0]
            },
        ],
        styles: {
            company: {
                alignment: "right"
            },
            header: {
                fontSize: 20,
                bold: true
            },
            subHeader: {
                fontSize: 12,
                bold: true
            },
            info: {
                fontSize: 8
            },
            totals: {
                fontSize: 15,
                bold: true
            }
        }
    }
    let promise = new Promise((resolve, reject) => {
        try {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition)
            resolve(pdfDocGenerator)
        }
        catch (e) {
            console.log(e)
            reject()
        }
    })
    return promise
}
export async function createPaymentsPdf(client, payments) {
    let data = []
    let widths = []
    let total = payments.reduce((acc, cur) => acc + parseFloat(cur.amount), 0)
    total = Math.round(total * 100) / 100
    let date = toDateString(new Date())
    widths = ["*", "*", "*", "*"]
    data.push(["Factuur", "Datum", "Bedrag", "Betalingswijze"])

    for (let payment of payments) {
        let row = []

        row.push(payment.invoiceNumber)
        row.push({ text: toDateString(payment.date) })
        row.push(toCurrency(payment.amount))
        row.push(payment.payMethod)


        data.push(row)
    }

    let imgDataUrl = await toDataURL(`${settings.company.logo}`)
    var docDefinition = {
        pageSize: "A4",
        pageMargins: [40, 20, 40, 120],
        footer: (currentPage, pageCount) => {
            if (currentPage == pageCount) {
                return [
                    // {
                    //     canvas: [
                    //         {
                    //             type: "rect",
                    //             x: 0,
                    //             y: 0,
                    //             w: 570,
                    //             h: 100,
                    //             lineColor: "black"
                    //         }
                    //     ],
                    //     margin: [10, 0, 0, 0],
                    // },
                    // {
                    //     layout: "noBorders",
                    //     table: {
                    //         headerRows: 1,
                    //         widths: ["*", "*", "*"],
                    //         body: [
                    //             [{
                    //                 stack: [{ text: "Openstaand: "+ toCurrency(total), style: "header" }
                    //                ]
                    //             }, "", {text: "" }]
                    //         ]
                    //     },
                    //     margin: [15, -100, 0, 0],

                    // },
                    { text: `pagina ${currentPage} van ${pageCount}`, alignment: "right", margin: [0, 5, 20, 0], fontSize: 8 }
                ]
            }
            else {
                return {
                    text: `pagina ${currentPage} van ${pageCount}`,
                    alignment: "right", margin: [0, 0, 40, 0]
                }
            }
        },
        content: [
            {
                columns: [
                    {
                        text: "Betalingsoverzicht",
                        style: "header"
                    },
                    [
                        { text: date, style: "company" },
                        { image: imgDataUrl, width: 140, absolutePosition: { x: 250, y: 10 } },

                    ]
                ]
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 200,
                        h: 90,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                margin: [0, 20, 0, 10]
            },
            {
                stack: [
                    { text: client.name, style: "subHeader" },
                    { text: client.address },
                    { text: client.postalcode },
                    { text: client.city },
                    { text: client.phone },
                ],
                absolutePosition: { x: 45, y: 77 }
            },
            {
                canvas: [
                    {
                        type: "rect",
                        x: 0,
                        y: 0,
                        w: 160,
                        h: 75,
                        r: 3,
                        lineColor: "black"
                    }
                ],
                absolutePosition: { x: 400, y: 100 }
            },
            {
                layout: "noBorders",
                table: {
                    widths: ["*"],
                    body: [[{ text: "Totaal:", style: "header" }],
                    [{ text: toCurrency(total), style: "header" }]
                    ]
                },
                absolutePosition: { x: 410, y: 100 }
            },
            {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: widths,
                    body: data
                },
                margin: [0, 20, 0, 0]
            },
        ],
        styles: {
            company: {
                alignment: "right"
            },
            header: {
                fontSize: 20,
                bold: true
            },
            subHeader: {
                fontSize: 12,
                bold: true
            },
            info: {
                fontSize: 8
            },
            totals: {
                fontSize: 15,
                bold: true
            }
        }
    }
    let promise = new Promise((resolve, reject) => {
        try {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition)
            resolve(pdfDocGenerator)
        }
        catch (e) {
            console.log(e)
            reject()
        }
    })
    return promise
}
// export async function printOrder(order, options) {
//     let pdfDocGenerator = await createOrderPdf(order, options)
//     pdfDocGenerator.getBuffer(async buffer => {
//         //         let res = await fetch(
//         //             `${config.api.host}/printers/create-job`,
//         //             {
//         //                 method: "POST",
//         //                 body: JSON.stringify({options:options}),
//         //                 headers: {
//         //                     "Content-Type": "application/json"
//         //                 }
//         //             }
//         //         )
//         //         let post = await res.json()
//         //         let job_id= await post['job-attributes-tag']['job-id'] 
//         //         console.log(job_id)
//         //          res = await fetch(
//         //             `${config.api.host}/printers/send-document`,
//         //             {
//         //                 method: "POST",
//         //                 body: JSON.stringify({job_id:job_id,options:options,buffer:buffer}),
//         //                 headers: {
//         //                     "Content-Type": "application/json"
//         //                 }
//         //             }
//         //         )
//         //          post = await res.json()
//         //        console.log(post)
//         //        res = await fetch(
//         //         `${config.api.host}/printers/get-jobs`,
//         //         {
//         //             method: "POST",
//         //             body: JSON.stringify({options:options}),
//         //             headers: {
//         //                 "Content-Type": "application/json"
//         //             }
//         //         }
//         //     )
//         //      post = await res.json()
//         //    console.log(post)
//         store.state.ws.send(
//             JSON.stringify({ event: "printOrder", data: { buffer: buffer, options: options } })
//         )
//         order.printed = true
//         if(order.status<2)order.status=2
//         store.commit("orderPrinted", order)
//         store.state.ws.send(
//             JSON.stringify({ event: "orderPrinted", data: order })
//         )
//     })
// }
export async function emailOrder(order, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOrderPdf(order, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((buffer) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(buffer)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function downloadOrder(order, options) {
    let pdfDocGenerator = await createOrderPdf(order, options)
    pdfDocGenerator.download(`Pakbon ${order.orderNumber}.pdf`)
}
export async function toDataURL(src) {
    var image = new Image();
    image.crossOrigin = 'Anonymous';
    let promise = new Promise((resolve, reject) => {
        try {
            image.onload = function image() {
                var canvas = document.createElement('canvas');
                var context = canvas.getContext('2d');
                canvas.height = this.naturalHeight;
                canvas.width = this.naturalWidth;
                context.drawImage(this, 0, 0);
                var dataURL = canvas.toDataURL('image/png');
                resolve(dataURL)
            }
        }
        catch (e) {
            reject()
            console.log(e)
        }
    })
    image.src = src
    return promise
}
async function createInvoicePdf(invoice, options) {
    try {
        let _invoiceTemplate = settings.invoiceTemplate
        if (invoice.client.invoiceTemplate != null) {
            _invoiceTemplate = invoice.client.invoiceTemplate
        }
        if (options.invoiceTemplate != null) {
            _invoiceTemplate = options.invoiceTemplate
        }
        const invoiceTemplate = getInvoiceTemplate(_invoiceTemplate)
        let array = await fetchGET("invoiceDebit", invoice.clientNumber)
        let totalDebit = array.length ? array[0].totalDebit : 0
        let reminderType = options.reminderType > 0 ? options.reminderTypes[options.reminderType] : ""
        let tables = []
        let imgDataUrl = await toDataURL(`${settings.company.logo}`)
        let qrcode = await QRCode.toDataURL(`${config.app.host}/createPayment/${invoice._id}`)
        let rowCount = 0
        let invoiceNumber = invoice.invoiceNumber
        let date = toDateString(invoice.date)
        let dueDate = toDateString(invoice.dueDate)
        let client = invoice.client
        let orders = invoice.orders
        let invoiceColumns = settings.invoiceColumns
        invoiceColumns = client.export === true && client.exportEU == false ? invoiceColumns : invoiceColumns.filter(c => c.type != 'export')
        let columnsLength = invoiceColumns.length
        let emptyRow = Array(columnsLength).fill("")
        for (let order of orders) {
            let data = []
            let columns = []
            let widths = []
            let footers = [{ text: "Ordertotaal:", bold: true, colSpan: 2 }, "", toCurrency(order.netTotal)]
            for (let i = 0; i < columnsLength; i++) {
                widths.push(invoiceColumns[i].width)
                columns.push({ text: invoiceColumns[i].text, style: "tableHeader" })
            }
            data.push(columns)
            for (let item of order.items) {
                let row = []
                for (let i = 0; i < columnsLength; i++) {
                    if (client.export === true && client.exportEU === false && invoiceColumns[i].value == 'name') {
                        row.push(`${item.name}; Netto: ${toDouble(item.totalWeight)}KG; Bruto: ${toDouble(item.totalWeight * 1.06)}KG`)
                    }
                    else if (invoiceColumns[i].type == "number") row.push(item[invoiceColumns[i].value])
                    else if (invoiceColumns[i].type == "currency") row.push(toCurrency(item[invoiceColumns[i].value]))
                    else if (invoiceColumns[i].type == "html") {
                        let html = htmlToPdfmake(item[invoiceColumns[i].value])
                        row.push(html)
                    }
                    else row.push(item[invoiceColumns[i].value])
                }
                data.push(row)
                rowCount++
            }
            if (client.export === true && client.exportEU === false) {
                let nameIndex = invoiceColumns.findIndex(col => col.value == 'name')
                let exportEORINumber = Array(columnsLength).fill("")
                exportEORINumber[nameIndex] = ["EORI-nr exporteur: " + settings.company.EORINumber]
                let importEORINumber = Array(columnsLength).fill("")
                importEORINumber[nameIndex] = ["EORI-nr importeur: " + client.EORINumber]
                let REXNumber = Array(columnsLength).fill("")
                REXNumber[nameIndex] = ["Rex-nr: " + settings.company.REXNumber]
                let REXStatement2 = Array(columnsLength).fill("")
                REXStatement2[nameIndex] = [`${settings.company.REXStatement} ${settings.company.city}, ${settings.company.country}. ${toDateString(invoice.date)}, ${settings.company.name}`]
                let incoterm = Array(columnsLength).fill("")
                incoterm[nameIndex] = ["Incoterm: " + client.incoterm]
                let currencyResponse = await fetch('https://api.freecurrencyapi.com/v1/latest?apikey=q4zdWXTwiQKOnFpdF8FWGf016OV4kpXAWm9FGTys&currencies=GBP&base_currency=EUR')
                let currency = await currencyResponse.json()
                let exchangeRate = currency.data.GBP
                let converted = Array(columnsLength).fill("")
                let convertedGrossTotal = (invoice.grossTotal * exchangeRate).toFixed(2)
                converted[nameIndex] = [`Total due amount: £ ${convertedGrossTotal}`]
                data.push(exportEORINumber, importEORINumber, REXNumber, REXStatement2, incoterm, converted)
            }
            if (columnsLength > 2) {
                for (let i = 0; i < columnsLength - 3; i++) {
                    footers.unshift("")
                }
                data.push(footers)
            }
            let orderHeader = order.clientReference ? "Leverdatum: " + toDateString(order.date) + " | Ordernr: " + order.orderNumber + " | Klantref: " + order.clientReference : "Leverdatum: " + toDateString(order.date) + " | Ordernr: " + order.orderNumber
            data.push(emptyRow)
            tables.push(
                { text: orderHeader, style: "info" },
                {
                    pageBreak: () => {
                        if (rowCount >= 10) {
                            rowCount = 0
                            return "before"
                        }
                    },
                    layout: "lightHorizontalLines",
                    table: {
                        headerRows: 1,
                        widths: widths,
                        body: data
                    },
                    margin: [0, 0, 0, 0],
                    fontSize: 10
                })
        }
        let VAT = invoice.VAT
        let body = [
            [{ text: "Te betalen:", style: "totals", alignment: "right" }, { text: toCurrency(invoice.debit), style: "totals" }],
            [{ text: "Reeds ontvangen:", style: "info", alignment: "right" }, { text: toCurrency(invoice.credit), style: "info" }],
            [{ text: "Excl. BTW: ", style: "info", alignment: "right" }, { text: toCurrency(invoice.netTotal), style: "info" }],
            [{ text: `Totaal BTW: `, style: "info", alignment: "right" }, { text: `${toCurrency(invoice.totalVAT)}`, style: "info" }],
            [{ text: "Incl. BTW: ", style: "info", alignment: "right" }, { text: toCurrency(invoice.grossTotal), style: "info" }],

        ]
        let VATBase = [[{ text: "BTW", bold: true, style: "info" }, { text: "Grondslag", bold: true, style: "info" }, { text: "BTW-bedrag", bold: true, style: "info" }]]
        let arr
        for (let vat of VAT) {
            arr = [{ text: `${vat.amount}%: `, style: "info" }, { text: `${toCurrency(vat.total / vat.amount * 100)}`, style: "info" }, { text: `${toCurrency(vat.total)}`, style: "info" }]
            VATBase.push(arr)
        }
        let invoiceType = invoice.grossTotal >= 0 ? "FACTUUR" : "CREDITFACTUUR"
        let companyInfo = options.stationary ? {
            stack: [{ text: reminderType, style: "subHeader", absolutePosition: invoiceTemplate.positions.reminderType }, {
                layout: "lightHorizontalLines",
                table: {
                    widths: [75, 75],
                    body: [
                        [{
                            text: invoiceType, style: "subHeader", colSpan: 2, alignment: "center"
                        }, ""],
                        [
                            {
                                text: "Nummer: ", alignment: "left"
                            },
                            {
                                text: invoiceNumber,
                                alignment: "left"
                            }
                        ],
                        [
                            {
                                text: "Datum: ", alignment: "left"
                            },
                            {
                                text: date,
                                alignment: "left"
                            }
                        ],
                        [
                            {
                                text: "Vervaldatum: ", alignment: "left"
                            },
                            {
                                text: dueDate,
                                alignment: "left"
                            }
                        ],
                        [{
                            text: "Klantnr: ", alignment: "left"
                        }, { text: client.clientNumber, alignment: "left" }]
                    ]
                },
                absolutePosition: invoiceTemplate.positions.invoiceDetails
            },
            {
                canvas: invoiceTemplate.positions.invoiceDetailsCanvas,
                absolutePosition: { x: 0, y: 0 }
            }]
        } : {
            stack: [{ text: reminderType, style: "subHeader", absolutePosition: invoiceTemplate.positions.reminderType },
            {
                layout: "lightHorizontalLines",
                table: {
                    widths: [75, 75],
                    body: [
                        [{
                            text: invoiceType, style: "subHeader", colSpan: 2, alignment: "center"
                        }, ""],
                        [
                            {
                                text: "Nummer: ", alignment: "left"
                            },
                            {
                                text: invoiceNumber,
                                alignment: "left"
                            }
                        ],
                        [
                            {
                                text: "Datum: ", alignment: "left"
                            },
                            {
                                text: date,
                                alignment: "left"
                            }
                        ],
                        [
                            {
                                text: "Vervaldatum: ", alignment: "left"
                            },
                            {
                                text: dueDate,
                                alignment: "left"
                            }
                        ],
                        [{
                            text: "Klantnr: ", alignment: "left"
                        }, { text: client.clientNumber, alignment: "left" }]
                    ]
                },
                absolutePosition: invoiceTemplate.positions.invoiceDetails
            },
            {
                canvas: invoiceTemplate.positions.invoiceDetailsCanvas,
                absolutePosition: { x: 0, y: 0 }
            }
            ]
        }
        if (options.qrcode) companyInfo.stack.push({ text: "Direct betalen(open uw camera)", absolutePosition: invoiceTemplate.positions.qrcodeInfo },
            { image: qrcode, width: 60, absolutePosition: invoiceTemplate.positions.qrcode })
        let companyLogo = options.stationary ? {
            stack: [{},
            {
                layout: "noBorders",
                table: {
                    widths: [20, "*"],
                    body: [[{ text: "Aan:", fontSize: 10 }, { text: client.name, fontSize: 10, bold: true }],
                    ["", { text: client.address, style: "companyInfo" }],
                    ["", { text: `${client.postalcode} ${client.city}`, style: "companyInfo" }],
                    ["", { text: client.phone, style: "companyInfo" }],
                    ]
                },
                absolutePosition: invoiceTemplate.positions.companyInfo
            }, {
                canvas: invoiceTemplate.positions.companyInfoCanvas,
                absolutePosition: { x: 0, y: 0 }
            },
            ]
        } : {
            stack: [{ image: imgDataUrl, width: 200, absolutePosition: invoiceTemplate.positions.logo },
            {
                layout: "noBorders",
                table: {
                    widths: [20, "*"],
                    body: [[{ text: "Aan:", fontSize: 10 }, { text: client.name, fontSize: 10, bold: true }],
                    ["", { text: client.address, style: "companyInfo" }],
                    ["", { text: `${client.postalcode} ${client.city}`, style: "companyInfo" }],
                    ["", { text: client.phone, style: "companyInfo" }],
                    ["", { text: client.VATNumber, style: "companyInfo" }],
                    ]
                },
                absolutePosition: invoiceTemplate.positions.companyInfo
            }, {
                canvas: invoiceTemplate.positions.companyInfoCanvas,
                absolutePosition: { x: 0, y: 0 }
            },]
        }
        var docDefinition = {
            info: {
                title: `Factuur ${invoiceNumber}`,
                author: 'BonBon'
            },
            pageSize: "A4",
            pageMargins: [30, 250, 40, 160],
            header: () => {
                return {
                    columns: [
                        companyInfo,
                        companyLogo
                    ]
                }
            },
            footer: (currentPage, pageCount) => {
                if (currentPage == pageCount) {
                    return [...invoiceTemplate.footer(currentPage, pageCount, options, toCurrency, totalDebit, VATBase, body, settings)]
                }
                else {
                    return {
                        text: `pagina ${currentPage} van ${pageCount}`,
                        alignment: "right", margin: [0, 0, 40, 0], fontSize: 8
                    }
                }
            },
            content: [
                {
                    stack: tables
                },
            ],
            styles: invoiceTemplate.styles
        }
        let promise = new Promise((resolve, reject) => {
            try {
                const pdfDocGenerator = pdfMake.createPdf(docDefinition)
                resolve(pdfDocGenerator)
            }
            catch (e) {
                console.log(e)
                reject()
            }
        })
        return promise
    } catch (err) {
        console.log(err)
    }
}
export async function viewInvoice(invoice, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createInvoicePdf(invoice, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printInvoice(invoice, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createInvoicePdf(invoice, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function viewQuote(quote, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createQuotePdf(quote, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printQuote(quote, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOrderPdf(quote, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function emailQuote(quote, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createQuotePdf(quote, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((buffer) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(buffer)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function downloadQuote(quote, options) {
    let pdfDocGenerator = await createQuotePdf(quote, options)
    pdfDocGenerator.download(`Offerte ${quote.quoteNumber}.pdf`)
}
export async function viewOrder(order, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOrderPdf(order, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printOrder(order, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOrderPdf(order, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function viewOpenOrders(client, orders, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOpenOrdersPdf(client, orders, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printOpenOrders(client, orders, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOpenOrdersPdf(client, orders, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function emailOpenOrders(client, orders, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOpenOrdersPdf(client, orders, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((buffer) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(buffer)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function viewOutstandingInvoices(client, invoices, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOutstandingInvoicesPdf(client, invoices, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printOutstandingInvoices(client, invoices, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOutstandingInvoicesPdf(client, invoices, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function emailOutstandingInvoices(client, invoices, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOutstandingInvoicesPdf(client, invoices, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((buffer) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(buffer)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function viewTotals(totals, routes, categories) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createTotalsPdf(totals, routes, categories)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printTotals(totals, routes, categories) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createTotalsPdf(totals, routes, categories)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function emailInvoice(invoice, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createInvoicePdf(invoice, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((buffer) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(buffer)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function downloadInvoice(invoice, options) {
    let pdfDocGenerator = await createInvoicePdf(invoice, options)
    pdfDocGenerator.download(`Factuur ${invoice.invoiceNumber}.pdf`)
}
// export async function printInvoice(invoice, options,totalDebit) {
//     console.log(invoice)
//     let pdfDocGenerator = await createInvoicePdf(invoice,options,totalDebit)
//     pdfDocGenerator.getBuffer(buffer => {
//         store.state.ws.send(
//             JSON.stringify({ event: "printInvoice", data: { buffer: buffer, invoice: invoice, options: options } })
//         )
//     })
//     invoice.printed=true
//     if(invoice.status<2)invoice.status=2
//     store.commit("invoicePrinted", invoice)
//             store.state.ws.send(
//                 JSON.stringify({ event: "invoicePrinted", data: invoice })
//             )
// }
export async function createTotalsPdf(totals, routes, categories) {
    function pushContent(data, route, category, i) {
        return [
            {
                layout: "noBorders",
                table: {
                    widths: ["auto", "auto", "auto", "auto", "auto", "auto"],
                    body: [
                        [{ text: `Route:` }, { text: `${route}`, style: "header" }, { text: `Categorie:` }, { text: `${category}`, style: "header" }, { text: `Datum:` }, { text: `${toDateString(new Date())}`, style: "subHeader" }],
                    ]
                }
            },
            {
                columns: [
                    {
                        table: {
                            widths: ["auto", "auto", "auto"],
                            body: data
                        },
                        margin: [0, 20, 0, 0],
                        style: "body",
                        pageBreak: pageBreak
                    },
                    table(i)
                ]
            }
        ]
    }
    function table(i) {
        if (i < max) return
        return {
            table: {
                widths: ["auto", "auto", "auto"],
                body: data2
            },
            margin: [0, 20, 0, 0],
            style: "body2",
            pageBreak: pageBreak
        }
    }
    try {
        let data
        var data2
        let content = []
        var pageBreak = "after"
        var max = 23
        let i = 0
        let collis = 0
        let totalWeight = 0
        let route
        let category
        let row
        let row2
        if (routes && !categories) {
            category = "Alles"
            let last = Object.values(totals).length - 1
            let count = 0
            for (let route in totals) {
                data = []
                data2 = []
                i = 0
                collis = 0
                totalWeight = 0
                data.push(["Artikel", "Aantal", "Klaar"])
                data2.push(["Artikel", "Aantal", "Klaar"])
                for (let item in totals[route]) {
                    row = []
                    row2 = []
                    collis += parseFloat(totals[route][item].packages)
                    totalWeight += parseFloat(totals[route][item].totalWeight)
                    if (i < max) {
                        row.push(item)
                        row.push({ text: `${toDouble(totals[route][item].amount)}${totals[route][item].unit}`, style: "amount" })
                        row.push(" ")
                        data.push(row)
                    }
                    else {
                        row2.push(item)
                        row2.push({ text: `${toDouble(totals[route][item].amount)}${totals[route][item].unit}`, style: "amount" })
                        row2.push(" ")
                        data2.push(row2)
                    }
                    i++
                }
                if (i < 25) {
                    data.push([{ text: "Collis", style: "amount" }, { text: collis.toFixed(0), style: "amount" }, ""])
                    data.push([{ text: "Gewicht", style: "amount" }, { text: totalWeight.toFixed(0), style: "amount" }, ""])
                }
                else {
                    data2.push([{ text: "Collis", style: "amount" }, { text: collis.toFixed(0), style: "amount" }, ""])
                    data2.push([{ text: "Gewicht", style: "amount" }, { text: totalWeight.toFixed(0), style: "amount" }, ""])
                }
                if (count == last) pageBreak = ""
                content.push(pushContent(data, route, category, i))
                count++
            }
        }
        else if (!routes && categories) {
            route = "Alles"
            let last = Object.values(totals).length - 1
            let count = 0
            for (let category in totals) {
                data = []
                data2 = []
                i = 0
                collis = 0
                totalWeight = 0
                data.push(["Artikel", "Aantal", "Klaar"])
                data2.push(["Artikel", "Aantal", "Klaar"])
                for (let item in totals[category]) {
                    row = []
                    row2 = []
                    collis += parseFloat(totals[category][item].packages)
                    totalWeight += parseFloat(totals[category][item].totalWeight)
                    if (i < max) {
                        row.push(item)
                        row.push({ text: `${toDouble(totals[category][item].amount)}${totals[category][item].unit}`, style: "amount" })
                        row.push(" ")
                        data.push(row)
                    }
                    else {
                        row2.push(item)
                        row2.push({ text: `${toDouble(totals[category][item].amount)}${totals[category][item].unit}`, style: "amount" })
                        row2.push(" ")
                        data2.push(row2)
                    }
                    i++
                }
                if (i < 25) {
                    data.push([{ text: "Collis", style: "amount" }, { text: collis.toFixed(0), style: "amount" }, ""])
                    data.push([{ text: "Gewicht", style: "amount" }, { text: totalWeight.toFixed(0), style: "amount" }, ""])
                }
                else {
                    data2.push([{ text: "Collis", style: "amount" }, { text: collis.toFixed(0), style: "amount" }, ""])
                    data2.push([{ text: "Gewicht", style: "amount" }, { text: totalWeight.toFixed(0), style: "amount" }, ""])
                }
                if (count == last) pageBreak = ""
                content.push(pushContent(data, route, category, i))
                count++
            }
        }
        else if (routes && categories) {
            let last = Object.values(totals).length - 1
            let count = 0
            for (let route in totals) {
                let last2 = Object.values(totals[route]).length - 1
                let count2 = 0
                for (let category in totals[route]) {
                    data = []
                    data2 = []
                    i = 0
                    collis = 0
                    totalWeight = 0
                    data.push(["Artikel", "Aantal", "Klaar"])
                    data2.push(["Artikel", "Aantal", "Klaar"])
                    for (let item in totals[route][category]) {
                        row = []
                        row2 = []
                        collis += parseFloat(totals[route][category][item].packages)
                        totalWeight += parseFloat(totals[route][category][item].totalWeight)
                        if (i < max) {
                            row.push(item)
                            row.push({ text: `${toDouble(totals[route][category][item].amount)}${totals[route][category][item].unit}`, style: "amount" })
                            row.push(" ")
                            data.push(row)
                        }
                        else {
                            row2.push(item)
                            row2.push({ text: `${toDouble(totals[route][category][item].amount)}${totals[route][category][item].unit}`, style: "amount" })
                            row2.push(" ")
                            data2.push(row2)
                        }
                        i++
                    }
                    if (i < 25) {
                        data.push([{ text: "Collis", style: "amount" }, { text: collis.toFixed(0), style: "amount" }, ""])
                        data.push([{ text: "Gewicht", style: "amount" }, { text: totalWeight.toFixed(0), style: "amount" }, ""])
                    }
                    else {
                        data2.push([{ text: "Collis", style: "amount" }, { text: collis.toFixed(0), style: "amount" }, ""])
                        data2.push([{ text: "Gewicht", style: "amount" }, { text: totalWeight.toFixed(0), style: "amount" }, ""])
                    }
                    if (count == last && count2 == last2) pageBreak = ""
                    content.push(pushContent(data, route, category, i))
                    count2++
                }
                count++
            }
        }
        else if (!routes && !categories) {
            route = "Alles"
            category = "Alles"
            pageBreak = ""
            collis = 0
            totalWeight = 0
            data = []
            data2 = []
            i = 0
            data.push(["Artikel", "Aantal", "Klaar"])
            data2.push(["Artikel", "Aantal", "Klaar"])
            for (let item in totals) {
                row = []
                row2 = []
                collis += parseFloat(totals[item].packages)
                totalWeight += parseFloat(totals[item].totalWeight)
                if (i < max) {
                    row.push(item)
                    row.push({ text: `${toDouble(totals[item].amount)}${totals[item].unit}`, style: "amount" })
                    row.push(" ")
                    data.push(row)
                }
                else {
                    row2.push(item)
                    row2.push({ text: `${toDouble(totals[item].amount)}${totals[item].unit}`, style: "amount" })
                    row2.push(" ")
                    data2.push(row2)
                }
                i++
            }
            if (i < 25) {
                data.push([{ text: "Collis", style: "amount" }, { text: collis.toFixed(0), style: "amount" }, ""])
                data.push([{ text: "Gewicht", style: "amount" }, { text: totalWeight.toFixed(0), style: "amount" }, ""])
            }
            else {
                data2.push([{ text: "Collis", style: "amount" }, { text: collis.toFixed(0), style: "amount" }, ""])
                data2.push([{ text: "Gewicht", style: "amount" }, { text: totalWeight.toFixed(0), style: "amount" }, ""])
            }
            content.push(pushContent(data, route, category, i))
        }
        var docDefinition = {
            pageSize: "A4",
            pageMargins: [10, 5, 5, 5],
            content: content,
            styles: {
                header: {
                    fontSize: 30,
                    bold: true
                },
                subHeader: {
                    fontSize: 20,
                    bold: true
                },
                body: {
                    fontSize: 16
                },
                body2: {
                    fontSize: 16,
                },
                amount: {
                    fontSize: 20,
                    bold: true
                }
            }
        }
        let promise = new Promise((resolve, reject) => {
            try {
                const pdfDocGenerator = pdfMake.createPdf(docDefinition)
                resolve(pdfDocGenerator)
            }
            catch (e) {
                console.error(e)
                reject(e)
            }
        })
        return promise
    }
    catch (e) {
        console.error(e)
    }
}
export async function printAllLogistics(logistics) {
    let length = logistics.length
    let last = length - 1
    let pageBreak = "after"
    let a = 0
    let content = []
    for (a; a < length; a++) {

        // if(a===last)pageBreak=""
        let items = logistics[a].items
        let route = logistics[a].route
        let vehicle = logistics[a].vehicle
        let driver = logistics[a].driver

        let blength = items.length
        let blast = blength - 1
        let b = 0
        for (b; b < blength; b++) {
            if (a === last && b === blast) pageBreak = ""
            // if(b===blast)pageBreak="after"
            let data = []
            data.push(["Artikel", "Aantal"])
            let clength = items[b].length
            // let clast = clength - 1
            let c = 0
            for (c; c < clength; c++) {
                // if(b===blast&&a!==last)pageBreak="after"

                let row = []
                row.push({ text: items[b][c].name, style: "amount" })
                row.push({ text: `${items[b][c].amount}${items[b][c].unit}`, style: "amount" })
                data.push(row)
            }

            content.push(
                {
                    layout: "noBorders",
                    table: {
                        widths: [200, 200, "*"],
                        body: [
                            [{ text: [`Route:`, { text: `${route}`, style: "header" }] }, "", ""], [{ text: [`Voertuig:`, { text: `${vehicle}`, style: "header" }] }, { text: [`Chauffeur:`, { text: `${driver}`, style: "header" }] }, ""],
                        ]
                    }
                },
                {
                    columns: [
                        {
                            table: {
                                widths: [150, 50],
                                body: data
                            },
                            pageBreak: pageBreak,
                            margin: [0, 20, 0, 0],
                            style: "body"
                        }
                    ]
                }
            )

        }
    }
    var docDefinition = {
        pageSize: "A5",
        pageMargins: [15, 10, 5, 5],
        content: content,
        styles: {
            header: {
                fontSize: 25,
                bold: true
            },
            body: {
                fontSize: 16
            },
            body2: {
                fontSize: 16,
            },
            amount: {
                fontSize: 20,
                bold: true
            }
        }
    }

    pdfMake.createPdf(docDefinition).open()
    // const pdfDocGenerator = pdfMake.createPdf(docDefinition)
    // pdfDocGenerator.getBuffer(buffer => {
    //     store.state.ws.send(
    //         JSON.stringify({ event: "printInvoice", data: { buffer: buffer, options: {printer: "Expeditie",pageSize:"A5",copies:1} } })
    //     )
    // })

}
export async function printLogistic(logistic) {
    // let length = logistic.length
    // let last = length - 1
    let pageBreak = "after"
    let content = []

    // if(a===last)pageBreak=""
    let items = logistic.items
    let route = logistic.route
    let vehicle = logistic.vehicle
    let driver = logistic.driver

    let blength = items.length
    let blast = blength - 1
    let b = 0
    for (b; b < blength; b++) {
        // if(a===last&&b===blast)pageBreak=""
        // if(b===blast)pageBreak="after"
        let data = []
        data.push(["Artikel", "Aantal"])
        let clength = items[b].length
        let clast = clength - 1
        let c = 0
        for (c; c < clength; c++) {
            if (b === blast && c == clast) pageBreak = ""

            let row = []
            row.push({ text: items[b][c].name, style: "amount" })
            row.push({ text: `${items[b][c].amount}${items[b][c].unit}`, style: "amount" })
            data.push(row)
        }

        content.push(
            {
                layout: "noBorders",
                table: {
                    widths: ["auto", "auto", "auto"],
                    body: [
                        [{ text: [`Route:`, { text: `${route}`, style: "header" }] }, { text: [`Datum:`, { text: `${toDateString(new Date())}`, style: "subHeader" }] }, ""], [{ text: [`Voertuig:`, { text: `${vehicle}`, style: "header" }] }, { text: [`Chauffeur:`, { text: `${driver}`, style: "header" }] }, ""],
                    ]
                }
            },
            {
                columns: [
                    {
                        table: {
                            widths: [150, 50],
                            body: data
                        },
                        pageBreak: pageBreak,
                        margin: [0, 20, 0, 0],
                        style: "body"
                    }
                ]
            }
        )

    }

    var docDefinition = {
        pageSize: "A5",
        pageMargins: [15, 10, 5, 5],
        content: content,
        styles: {
            header: {
                fontSize: 25,
                bold: true
            },
            subHeader: {
                fontSize: 10,
                bold: true
            },
            body: {
                fontSize: 16
            },
            body2: {
                fontSize: 16,
            },
            amount: {
                fontSize: 20,
                bold: true
            }
        }
    }

    pdfMake.createPdf(docDefinition).open()
    // const pdfDocGenerator = pdfMake.createPdf(docDefinition)
    // pdfDocGenerator.getBuffer(buffer => {
    //     store.state.ws.send(
    //         JSON.stringify({ event: "printInvoice", data: { buffer: buffer, options: {printer: "Expeditie",pageSize:"A5",copies:1} } })
    //     )
    // })

}
export async function printLogisticItems(items, route, vehicle, driver) {
    let data = []
    data.push(["Artikel", "Aantal"])

    for (let item of items) {
        let row = []
        row.push({ text: item.name, style: "amount" })
        row.push({ text: `${item.amount}${item.unit}`, style: "amount" })
        data.push(row)

    }

    var docDefinition = {
        pageSize: "A5",
        pageMargins: [15, 10, 5, 5],
        content: [
            {
                layout: "noBorders",
                table: {
                    widths: [200, 200, "*"],
                    body: [
                        [{ text: [`Route:`, { text: `${route}`, style: "header" }] }, "", ""], [{ text: [`Voertuig:`, { text: `${vehicle}`, style: "header" }] }, { text: [`Chauffeur:`, { text: `${driver}`, style: "header" }] }, ""],
                    ]
                }
            },
            {
                columns: [
                    {
                        table: {
                            widths: [150, 50],
                            body: data
                        },
                        margin: [0, 20, 0, 0],
                        style: "body"
                    }
                ]
            }
        ],
        styles: {
            header: {
                fontSize: 25,
                bold: true
            },
            body: {
                fontSize: 16
            },
            body2: {
                fontSize: 16,
            },
            amount: {
                fontSize: 20,
                bold: true
            }
        }
    }

    // pdfMake.createPdf(docDefinition).open()
    const pdfDocGenerator = pdfMake.createPdf(docDefinition)
    pdfDocGenerator.getBuffer(buffer => {
        store.state.ws.send(
            JSON.stringify({ event: "printInvoice", data: { buffer: buffer, options: { printer: "Expeditie", pageSize: "A5", copies: 1 } } })
        )
    })

}
export async function printLogisticNative(items, route, vehicle, driver) {
    let data = []
    data.push(["Artikel", "Aantal"])

    for (let item of items) {
        let row = []
        row.push({ text: item.name, style: "amount" })
        row.push({ text: `${item.amount}${item.unit}`, style: "amount" })
        data.push(row)

    }

    var docDefinition = {
        pageSize: "A5",
        pageMargins: [15, 10, 5, 5],
        content: [
            {
                layout: "noBorders",
                table: {
                    widths: [200, 200, "*"],
                    body: [
                        [{ text: [`Route:`, { text: `${route}`, style: "header" }] }, "", ""], [{ text: [`Voertuig:`, { text: `${vehicle}`, style: "header" }] }, { text: [`Chauffeur:`, { text: `${driver}`, style: "header" }] }, ""],
                    ]
                }
            },
            {
                columns: [
                    {
                        table: {
                            widths: [150, 50],
                            body: data
                        },
                        margin: [0, 20, 0, 0],
                        style: "body"
                    }
                ]
            }
        ],
        styles: {
            header: {
                fontSize: 25,
                bold: true
            },
            body: {
                fontSize: 16
            },
            body2: {
                fontSize: 16,
            },
            amount: {
                fontSize: 20,
                bold: true
            }
        }
    }
    pdfMake.createPdf(docDefinition).open()
}
export async function viewLabel(item, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createLabelPdf(item, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printLabel(item, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createLabelPdf(item, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function viewOrderLabel(order, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOrderLabelPdf(order, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBlob((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function printOrderLabel(order, options) {
    store.commit("dataLoaded", false)
    let pdfDocGenerator = await createOrderLabelPdf(order, options)
    store.commit("setProgress", 50)
    let promise = new Promise((resolve, reject) => {
        pdfDocGenerator.getBuffer((dataUrl) => {
            try {
                store.commit("setProgress", 100)
                store.commit("dataLoaded", true)
                resolve(dataUrl)
            } catch (err) {
                reject(err)
            }
        })
    })
    return promise
}
export async function createLabelPdf(item, options) {
    console.log(options)
    let data = []
    let date = new Date(item.productionDate)
    let productionDate = new Date(item.productionDate)
    let weight = []
    let ingredients = []
    let shelfLifeRefrigerated = []
    let shelfLifeFrozen = []
    let lotNumber = [
        { text: "Lotnummer:", bold: true },
        { text: item.lotNumber }
    ]
    let birthCountry = [
        { text: "Geboren:", bold: true },
        { text: item.birthCountry }
    ]
    let residenceCountry = [
        { text: "Gehouden/Gemest:", bold: true },
        { text: item.residenceCountry }
    ]
    let slaughterCountry = [
        { text: "Geslacht:", bold: true },
        { text: item.slaughterCountry }
    ]
    let newDate = new Date(date)
    newDate.setDate(date.getDate() + item.shelfLifeRefrigerated)
    let expires = toDateString(newDate)
    weight.push({
        text: "Nettogewicht:",
        bold: true
    })
    weight.push({
        text: item.amount + "kg"
    })
    shelfLifeRefrigerated.push({
        text: "Ten minste houdbaar tot:(max 4°C)",
        bold: true
    })
    shelfLifeRefrigerated.push({ text: expires })
    if (item.shelfLifeFrozen) {
        newDate.setDate(date.getDate() + item.shelfLifeFrozen)
        expires = toDateString(newDate)
        shelfLifeFrozen.push({
            text: "Ten minste houdbaar tot:(min -18°C)",
            bold: true
        })
        shelfLifeFrozen.push({ text: expires })
    }
    if (item.ingredients) {
        ingredients.push({
            stack: [
                {
                    text: "Ingrediënten: ",
                    bold: true
                },
                { text: htmlToPdfmake(item.ingredients) }
            ],
            colSpan: 2
        })
    }
    if (item.unitAmount) data.push(weight)
    data.push([
        { text: "Productiedatum:", bold: true },
        { text: toDateString(productionDate) }
    ])
    if (!item.frozen) data.push(shelfLifeRefrigerated)
    if (item.shelfLifeFrozen) data.push(shelfLifeFrozen)
    if (item.ingredients) data.push(ingredients)
    data.push(lotNumber)
    if (item.category == "Rund") data.push(birthCountry)
    data.push(residenceCountry)
    data.push(slaughterCountry)
    let imgDataUrl = await toDataURL(`${settings.company.logo}`)
    let halalDataUrl = await toDataURL(`/img/halallogo.png`)
    let margins = store.state.settings.labelPrinter.margins.split(",").map(x => parseInt(x))
    let pageSize = store.state.settings.labelPrinter.pageSize
    let ECNumberImage = store.state.settings.company.ECNumberImage
    let halal = item.halal ? true : false
    let imageArray = []
    let imageHeight = store.state.settings.labelPrinter.imageHeight
    imageArray.push({ image: ECNumberImage, alignment: "center", height: imageHeight, margin: [0, 5, 0, 0] })
    if (halal) imageArray.push({ image: halalDataUrl, alignment: "center", height: imageHeight, margin: [0, 5, 0, 0] })
    var docDefinition = {
        pageSize: pageSize,
        footer: () => {
            return {
                text: store.state.settings.labelPrinter.footer,
                style: "footer",
                alignment: "center"
            }
        },
        pageOrientation: store.state.settings.labelPrinter.pageOrientation,
        pageMargins: margins,
        content: [
            { text: item.name, style: "header", alignment: "center" },
            {
                layout: {
                    hLineWidth: (i, node) => {
                        return i === 0 || i === node.table.body.length ? 1 : 0.1
                    },
                    vLineWidth: (i, node) => {
                        return i === 0 || i === node.table.widths.length ? 1 : 0.1
                    },
                    paddingLeft: (i, node) => {
                        i, node
                        return 0
                    },
                    paddingRight: (i, node) => {
                        i, node
                        return 0
                    },
                    paddingTop: (i, node) => {
                        i, node
                        return 0
                    },
                    paddingBottom: (i, node) => {
                        i, node
                        return 0
                    },
                    borderLeft: (i, node) => {
                        i, node
                        return 0
                    }
                },
                table: {
                    widths: ["50%", "50%"],
                    body: data
                },
                margin: [0, 0, 0, 0],
            },
            {
                layout: "noBorders",
                table: {
                    widths: ["50%", "50%"],
                    heights: 40,
                    body: [
                        imageArray
                    ]
                }
            }
        ],
        defaultStyle: {
            fontSize: store.state.settings.labelPrinter.styles.defaultStyle.fontSize,
        },
        styles: {
            company: {
                alignment: "right"
            },
            header: {
                fontSize: store.state.settings.labelPrinter.styles.header.fontSize,
                bold: true
            },
            subHeader: {
                fontSize: store.state.settings.labelPrinter.styles.subHeader.fontSize,
                bold: true
            },
            footer: {
                fontSize: store.state.settings.labelPrinter.styles.footer.fontSize
            }
        }
    }
    if (options.showLogo) docDefinition.content.unshift({ image: imgDataUrl, width: store.state.settings.labelPrinter.logoSize.width, height: store.state.settings.labelPrinter.logoSize.height, alignment: "center" })
    let promise = new Promise((resolve, reject) => {
        try {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition)
            resolve(pdfDocGenerator)
        }
        catch (e) {
            console.log(e)
            reject()
        }
    })
    return promise
}
export function createOrderLabelPdf(order) {
    let data = []
    let date = new Date(order.date)
    let total = 0
    for (let item of order.items) {
        let item2 = []
        let weight = []
        let ingredients = []
        let shelfLife = []
        let shelfLifeFrozen = []
        let newDate = new Date(date)
        newDate.setDate(date.getDate() + item.shelfLife)
        let expires = toDateString(newDate)
        item2.push({
            text: "Artikel:",
            bold: true
        })
        item2.push({
            text: item.name,
            bold: true
        })
        weight.push({
            text: "Gewicht:",
            bold: true
        })
        weight.push({
            text: item.amount + item.unit
        })
        shelfLife.push({
            text: "TGT:(max 4°C)",
            bold: true
        })
        shelfLife.push({ text: expires })
        if (item.shelfLifeFrozen) {
            newDate.setDate(date.getDate() + item.shelfLifeFrozen)
            expires = toDateString(newDate)
            shelfLifeFrozen.push({
                text: "TGT:(min -18°C)",
                bold: true
            })
            shelfLifeFrozen.push({ text: expires })
        }
        if (item.ingredients) {
            let string = item.ingredients
            ingredients.push({
                stack: [
                    {
                        text: "Ingrediënten: ",
                        bold: true
                    },
                    { text: string }
                ],
                colSpan: 2
            })
        }
        data.push(item2)
        data.push(weight)
        data.push(shelfLife)
        if (item.shelfLifeFrozen) data.push(shelfLifeFrozen)
        if (item.ingredients) data.push(ingredients)
        data.push([{ text: " ", colSpan: 2 }])
        total += parseFloat(item.amount)
    }
    data.push([
        { text: "Totaal", bold: true },
        { text: toDouble(total) + "kg" }
    ])
    data.push([
        { text: "Leverdatum:", bold: true },
        { text: toDateString(date) }
    ])
    var docDefinition = {
        pageSize: {
            width: 255.12,
            height: 107.72
        },
        footer: () => {
            return {
                text:
                    "Slagerij Witte Paleis B.V. \n Bos en Lommerweg 243 \n 1055 DV Amsterdam \n 020-6860449 [NL208087EG]",
                alignment: "center",
                fontSize: 5
            }
        },
        pageOrientation: "portrait",
        pageMargins: [7, 5, 5, 32],
        content: [
            {
                table: {
                    widths: ["100%"],
                    body: [
                        [
                            {
                                text: order.name,
                                fontSize: 15,
                                bold: true,
                                alignment: "center",
                                border: [false, false, false, false]
                            }
                        ]
                    ]
                }
            },
            {
                layout: {
                    hLineWidth: (i, node) => {
                        return i === 0 || i === node.table.body.length ? 1 : 0.1
                    },
                    vLineWidth: (i, node) => {
                        return i === 0 || i === node.table.widths.length ? 1 : 0.1
                    },
                    paddingLeft: (i, node) => {
                        i, node
                        return 0
                    },
                    paddingRight: (i, node) => {
                        i, node
                        return 0
                    },
                    paddingTop: (i, node) => {
                        i, node
                        return 0
                    },
                    paddingBottom: (i, node) => {
                        i, node
                        return 0
                    },
                    borderLeft: (i, node) => {
                        i, node
                        return 0
                    }
                },
                table: {
                    widths: ["50%", "50%"],
                    body: data
                },
                margin: [0, 0, 0, 0],
                fontSize: 6
            }
        ],
        styles: {
            company: {
                alignment: "right"
            },
            header: {
                fontSize: 30,
                bold: true
            },
            subHeader: {
                fontSize: 20,
                bold: true
            }
        }
    }
    let promise = new Promise((resolve, reject) => {
        try {
            const pdfDocGenerator = pdfMake.createPdf(docDefinition)
            resolve(pdfDocGenerator)
        }
        catch (e) {
            console.log(e)
            reject()
        }
    })
    return promise
}